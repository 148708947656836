.App {
}

#top-routes > canvas {
  max-width: 400px !important;
  height: auto !important;
  margin: 0 auto 2rem !important;
}

.dondaa-dash-nav {
  min-width: 10.5rem !important;
  flex-wrap: wrap;
  justify-content: center;
}

datalist {
  max-height: 15rem;
}

.chat-messages-container > p {
  margin: 1rem auto;
  padding: 0.4rem 1rem;
  width: fit-content;
}
