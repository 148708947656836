body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-orange {
  color: orange !important;
}

.highligted-cell {
  border-radius: 10px;
  margin: 0.1rem 0.25rem;
  padding: 0.25rem 0.75rem;
  background-color: aliceblue;
}

.table-btn{
  font-size: .8rem !important;
  padding: .25rem;
  margin: .2rem 0;
}
/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
} */
